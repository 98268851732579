








































import { Component } from 'vue-property-decorator';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import { UnitEntityModel, UnitQueryModel } from '@common-src/entity-model/unit-entity';
import UnitService from '@common-src/service/unit';

@Component
export default class UnitListComponent extends TableDialogFormComponent<UnitEntityModel, UnitQueryModel> {
    UnitEntityModel = UnitEntityModel;
    created() {
        this.initTable({
            service: new UnitService(),
            queryModel: new UnitQueryModel(),
            tableColumns: UnitEntityModel.getTableColumns()
        });
        this.getList();
    }
}

